<template>
  <div
    id="ccc-campaigns"
    class="ccc-campaigns"
    :fsp-key="identity"
    :style="bgStyle"
  >
    <AppCcc
      :content="content" 
      :context="context"
      :scene-data="sceneData"
      :cate-links="cateLinks"
    />
    <ShareAndLike
      v-if="isShowShareAndLike"
      :context="context"
      :scene-data="sceneData"
    />
    <!-- 钩子弹窗 && 挽留弹窗 -->
    <EntranceLeaveDialog
      class="not-fsp-element"
      :context="context"
      :scene-data="sceneData"
      :cate-links="cateLinks"
    />
    <!-- 规则弹窗 -->
    <RulesDialog
      v-if="dialogEnable.rulesDialog"
      :context="context"
      :scene-data="sceneData"
    />
    <!-- 底部导航tab -->
    <template v-if="showBottomTab">
      <CccBottomTab
        :context="context"
        :scene-data="sceneData"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import { parseQueryString } from '@shein/common-function'
import AppCcc from 'public/src/pages/components/ccc/Index.vue'
import ShareAndLike from './components/shareAndLike/index.vue'
import EntranceLeaveDialog from './components/modals/index.vue'
import RulesDialog from './components/rules/rulesDialog.vue'
import CccBottomTab from './components/bottomTab/index.vue'
import campaignsAnalysis from './analysis'

import { abtservice } from 'public/src/services/abt'
import { transfromAndCutImg } from 'public/src/services/resource/index'
import { cccxEventBus, deepFreeze } from 'public/src/pages/components/ccc/common/utils.js'

import { isLogin } from 'public/src/pages/common/utils/index.js'
import TMG from '@shein/time-management-guru'
import { markPoint } from 'public/src/services/mark/index.js'

import { Dialog, Button, Drawer } from '@shein/sui-mobile'
Vue.use(Dialog)
Vue.use(Button)
Vue.use(Drawer)

/**
 * 请求专题数据
 */
const fetchCampaignsData = async (route) => {
  const params = {
    identity: route?.params?.identity,
    tabIndex: route?.query?.tabIndex || 0,
    isLogin: typeof SHEIN_COMMON !== 'undefined' ? isLogin() : false
  }
  const { componentId, componentAdp } = route?.query || {}
  if (componentId && componentAdp) {
    params.componentId = componentId
    params.componentAdp = componentAdp
  }
  const data = await TMG.useQuickRequest(`campaigns/getCampaignsPageData/${route?.params?.identity}`, params)
  return data
}

/**
 * 处理沉浸式组件
 */
const handleImmersiveComponent = async (content, store) => {
  // 首个组件是否开启沉浸式
  const hasImmersive = content.some((item, index) => {
    if (item.props?.metaData?.immersive && index === 0) {
      store.commit('changeHomeImmersiveInfo', {
        commonHeaderBg: {
          showBlock: true, // 沉浸式开关
          topTextColor: '#ffffff',
          immersiveFlag: false,
          backgroundColor: item.props.metaData.backgroundColor,
        }
      })
      return true
    }
    // h5沉浸式组件
    if (item.styleType === 'IMMERSIVE_BANNER' && index === 0) {
      store.commit('changeHomeImmersiveInfo', {
        commonHeaderBg: {
          showBlock: true, // 沉浸式开关
          topTextColor: '#ffffff',
          immersiveFlag: false,
          backgroundColor: 'rgba(0,0,0,0)',
        }
      })
      return true
    }
  })
  if (!hasImmersive) {
    store.commit('changeHomeImmersiveInfo', {
      commonHeaderBg: {
        showBlock: false, // 沉浸式开关
      }
    })
  }
}

export default {
  name: 'CccCampaigns',
  components: {
    AppCcc,
    ShareAndLike,
    RulesDialog,
    EntranceLeaveDialog,
    CccBottomTab,
  },
  // SSR 调用将数据注入仓库
  async asyncData ({ store, context, route }) {
    // 相同专题不同参数需要更新数据
    function campaignsUpdate () {
      if (typeof window !== 'undefined') {
        const preRoute = window.__pre_route__
        window.__pre_route__ = route
        return [
          // 需要置顶商品不同
          (preRoute?.query?.componentAdp || route.query?.componentAdp) && preRoute?.query?.componentAdp !== route.query?.componentAdp,
          // 需要商品置顶组件不同
          (preRoute?.query?.componentId || route.query?.componentId) && preRoute?.query?.componentId !== route.query?.componentId,
        ].some(Boolean)
      }
    }
    const campaignsData = store.state['ccc-campaigns']
    if (!campaignsData?.context?.content?.identity) {
      // ssr或者客户端数据直出直接赋值
      if (context && context.ssrPageType === 'campaigns') {
        const content = context?.content?.content || [] 
        handleImmersiveComponent(content, store)
        store.state['ccc-campaigns'].context = deepFreeze(JSON.parse(JSON.stringify(context)))
        return Promise.resolve(context)
      } else {
        let data = {}
        if (typeof window !== 'undefined' && window.campaignsSsrData) {
          data = window.campaignsSsrData
        } else {
          data = await fetchCampaignsData(route)
        }
        handleImmersiveComponent(data.content.content, store)
        store.commit('ccc-campaigns/SET_DATA', deepFreeze(JSON.parse(JSON.stringify(data))))
        markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
      }
    } else if (campaignsData?.context?.content?.identity !== route?.params?.identity || campaignsUpdate()) {
      store.state['ccc-campaigns'].context = {} // 不同标识符情况下清空上一份数据
      let data = {}
      if (typeof window !== 'undefined' && window.fetchCampaignsSheinNewInfo && route?.params?.identity === 'shein_new') {
        const res = await window.fetchCampaignsSheinNewInfo
        data = res?.contextForSSR || await fetchCampaignsData(route)
      } else {
        data = await fetchCampaignsData(route)
      }
      data.prerenderSsr = false
      handleImmersiveComponent(data.content.content, store)
      store.commit('ccc-campaigns/SET_DATA', deepFreeze(JSON.parse(JSON.stringify(data))))
      markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
    }
  },
  provide() {
    return {
      cutImg: this.cutImg,
    }
  },
  data() {
    return {
      aod_id: '-'
    }
  },
  computed: {
    ...mapState('ccc-campaigns', ['context']),
    showBottomTab() {
      return this.context.content?.bottomTabConfig?.length >= 3
    },
    content() {
      return this.context?.content?.content || []
    },
    cateLinks() {
      return this.context?.content?.cateLinks
    },
    sceneData({ context }) {
      const contextData = context?.content || {}
      return {
        id: contextData?.id || '',
        identity: contextData?.identity || '',
        pageType: contextData?.pageType || '',
        pageFrom: 'campaigns',
        sceneName: contextData?.pageType || '',
        activityId: contextData?.id || '',
        activityNameEn: contextData?.identity || '',
      }
    },
    identity() {
      return this.sceneData?.identity || ''
    },
    bgStyle() {
      const { extend = '{}' } = this.context?.content || {}
      let extendParse
      try {
        extendParse = JSON.parse(extend)
      } catch (error) {
        extendParse = {}
      }
      const {
        color = '',
        fillType = '1',
        backgroundSize = '3',
        backgroundImgSrc = {},
      } = extendParse || {}
      const fillTypeMap = {
        '1': 'no-repeat', // 上传图片长度
        '2': 'repeat', // 实际页面长度
      }
      const mapSize = {
        '1': 'auto',
        '2': 'cover',
        '3': 'contain',
        '4': '100%',
      }
      return {
        backgroundColor: color,
        backgroundSize: mapSize[backgroundSize],
        backgroundRepeat: fillTypeMap[fillType],
        backgroundImage: backgroundImgSrc?.src ? `url(${backgroundImgSrc.src})` : 'none',
      }
    },
    isShowShareAndLike() {
      const extend = JSON.parse(this.context?.content?.extend || '{}')
      return extend?.isOpenLike || extend?.isOpenShare
    },
    dialogEnable () {
      const extend = this.context?.content?.extendParse || {}
      return {
        leaveModal: extend?.modals?.beforeLeave?.enable,
        rulesDialog: extend?.rule?.enable,
      }
    }
  },
  // watch: {
  //   context(newContext, oldContext) {
  //     if(newContext?.content?.identity !== oldContext?.content?.identity) {
  //       setTimeout(() => {
  //         this.getIciAodId()
  //         this.handlePageOnload()
  //       }, 0)
  //     }
  //   }
  // },
  beforeRouteEnter(to, from, next) {
    const isBrowser = typeof window !== 'undefined'
    const isFromClickTabBar = to?.query?.from_tabbar_new === '1'
    next((vm) => {
      if (isBrowser) {
        setTimeout(() => {
          vm.getIciAodId()
          // 表示当前为刷新页面
          vm.handlePageOnload()
        }, 0)
      }
      if (isFromClickTabBar && isBrowser) {
        $('.j-index-footer .j-index-tab-list-activity')
          .addClass('title-active')
          .siblings()
          .removeClass('title-active')
        $('.j-index-footer').show()
      }
    })
  },
  activated() {
    if (typeof window !== 'undefined') {
      // 触发免邮组件的显示隐藏
      cccxEventBus?.emit?.('freeShippingPage-will-hidden')
      // 后续需要优化
      $('.j-spa-goback, .j-dt-go-category-list-detail').show()
      $('.j-dt-go-category-index, .j-header-msg').hide()
    }
    // delete this.$store.state['ccc-campaigns'].context
  },
  deactivated() {
    this.pageOnload = false
    cccxEventBus?.emit?.('freeShippingPage-will-hidden')
    window.campaignsSsrData = null
  },
  destroyed () {
    window.campaignsSsrData = null
    this.$store.unregisterModule('ccc-campaigns')
    cccxEventBus?.off?.('freeShippingPage-will-hidden')
  },
  beforeMount () {
    if(this.context?.prerenderSsr){
      this.$el = document.querySelector('#prerender-app #ccc-campaigns')
    }
  },
  mounted() {
    if (window._gb_app_?.$store?.state?.rootStatus?.exchangingSpaScene) {
      window._gb_app_.$store.state.rootStatus.exchangingSpaScene = false // 显示页面的app
    }
  },
  methods: {
    handlePageOnload() {
      // 触发pv
      this.handlePageViewParams()
      window.appEventCenter.$emit('pageOnload')
    },
    handlePageViewParams() {
      const { id, identity, abtParams = {} } = this.context?.content || {}
      const { isRomwe = false } = this.context || {}
      const poskey = isRomwe ? 'RMActAbt' : 'SMActAbt'
      const targetKey = Object.keys(abtParams).find(key => key.includes(poskey))
      // const { bid = '-',  eid = '-', 'posKey': abtPosKey = '-' } = abtParams[targetKey] || {}
      const { composeId: compose_id = '', item_id = '', scene_id = '', contentCarrierId = '-', templateId: template_id = '-', src_module = '', src_identifier = '', src_tab_page_id = '', ici = '', productsource = ''  } = parseQueryString(location.search)

      let abtInfo = { sa: '' }
      if (targetKey?.length) {
        // eslint-disable-next-line @shein-aidc/abt/abt
        abtInfo = abtservice.getUserAbtResultForAnalysis({ posKeys: targetKey, abtInfo: abtParams })
        console.log('abtInfo: ', abtInfo)
      }

      campaignsAnalysis.refreshAnalysisParams({
        params: {
          mod1_id: id,
          name_en: identity,
          pagefrom: ici || productsource || '',
          aod_id: this.aod_id,
          abtest: '-`-`-',
          // crowd_id: '',
          new_flag: 1,
          compose_id,
          item_id,
          scene_id,
          content_id: contentCarrierId,
          template_id,
          src_module,
          src_identifier,
          src_tab_page_id,
          auto_page: scene_id ? 1 : 0,
        }
      })
    },
    getIciAodId() {
      const aod_id = new URLSearchParams(location.search).get('aod_id')
      this.aod_id = aod_id || '-'
      if (location.search.indexOf('ici') !== -1) {
        const iciSearchArr = decodeURIComponent(location.search).split('&')
        iciSearchArr.map((val) => {
          if (val && val.indexOf('ici') !== -1) {
            const iciStr = val.split('_')
            iciStr &&
              iciStr.map((v) => {
                if (v.indexOf('aod') > -1) {
                  // 优先使用 aod_id
                  this.aod_id = aod_id || v.split('=')[1] || '-'
                }
              })
          }
        })
      }
    },
    /** 图片裁切 */
    cutImg (imgUrl, designWidth, exp) {
      const { RESOURCE_SDK = {} } = this.context || {}
      const { deviceData = '', isSupportWeb = '', isSupprotCut = false, sceneMap = {} } = RESOURCE_SDK || {}

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        sceneMap,
        exp,
      }

      return transfromAndCutImg(cutData)
    }
  }
}
</script>

<style lang="less">
.ccc-campaigns {
  position: relative;
}
body {
  margin: 0;
}
* {
  box-sizing: border-box;
}
</style>
