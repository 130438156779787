import { cccxEventBus } from 'public/src/pages/components/ccc/common/utils.js'
import expose from 'public/src/services/expose/index.js'

// 始终悬浮的组件 悬浮高度 = 0
const alwaysFloatBottomComponents = [
  'BOTTOM_TAB', // 底部tab
]
// 全局底部悬浮 悬浮高度 = 始终悬浮的组件的高度 + 所有组件悬浮的总高度
const globalFloatComponents = [
  'MODALS_COUPON_FLOAT', // 弹窗优惠券底部悬浮
]
/*
非可视区才悬浮的组件 悬浮高度 = 始终悬浮的组件的高度 + 比自己层级高的组件的高度
接的组件布局必须最外层有个div包裹，例如：<div><div v-bottom-sticky={}>组件内容</div></div>
*/
const customFloatComponents = [
  'CLUB_PROMOTION', // 付费会员组件
  'ONE_IMAGE_COMPONENT', // 单图组件
]
// 所有悬浮组件的信息
let floatCompList = []

// 自定义组件的总高度
// let customHeightSum = 0

// zIndex枚举
const zIndexEnum = {
  'BOTTOM_TAB': 11,
  'CLUB_PROMOTION': 10,
  'ONE_IMAGE_COMPONENT': 10,
  'MODALS_COUPON_FLOAT': 9,
}

// 底部不留10px间距的组件
const notNeedBottomSpace = [
  'ONE_IMAGE_COMPONENT', // 单图组件
]

// 计算悬浮元素的高度
const calcElHeight = (el) => {
  return el.offsetHeight + 6  // 每个组件有6px的间距
}
// 始终悬浮组件的总高度
let alwaysFloatBottomComponentsHeight = 0

// 是否包含始终悬浮组件
let hasAlwaysFloatBottomComponents = false
// 判断元素是否在视口内
// function isContain(el) {
//   const totalHeight = window.innerHeight || document.documentElement.clientHeight;
//   const { top, right, bottom, left } = el.getBoundingClientRect();
//   return (top >= 0  && bottom <= totalHeight);
// }

// 统计监听次数
let observerNum = 0

// 初始化悬浮组件列表
const initFloatCompList = (el, binding) => {
  const { styleType = '' } = binding?.value || {}
  const params = {
    ...binding?.value,
    compHeight: calcElHeight(el) || 0,
    el,
  }
  if (!floatCompList.length) {
    floatCompList.push({
      ...params
    })
  } else {
    const isExist = floatCompList.some(item => {
      return item.styleType === styleType
    })
    if (!isExist) {
      floatCompList.push({
        ...params
      })
    }
    // 钩子弹窗和挽留弹窗会导致优惠券底部悬浮显示隐藏来回切换，所以要更新el
    if (styleType === 'MODALS_COUPON_FLOAT') {
      const modalIndex = floatCompList.findIndex(item => item.styleType === 'MODALS_COUPON_FLOAT')
      if (modalIndex !== -1) {
        floatCompList.splice(modalIndex, 1, {
          ...params
        })
      }
    }
  }
}
// 计算全局悬浮组件的高度, 全局悬浮组件的高度 = 始终悬浮的组件的高度 + 所有组件悬浮的总高度
const calcGlobalFloatCompHeightSum = (styleType) => {
  calcAlwaysFloatCompHeight(styleType)
  const globalList = floatCompList.filter(item => globalFloatComponents.includes(item.styleType))
  if (!globalList.length) return
  let globalHeight = 0
  floatCompList.forEach(item => {
    if (item.availableFloat) {
      globalHeight += item.compHeight
    }
  })
  if (!globalHeight || !hasAlwaysFloatBottomComponents) globalHeight += 10
  globalList[0].el.style.position = 'fixed'
  globalList[0].el.style.bottom = globalHeight + 'px'
  globalList[0].el.style.zIndex = zIndexEnum[styleType]
  globalList[0].bottomOffset = globalHeight
}
// 计算自定义组件的高度 高度 = 始终悬浮的组件的高度 + 比自己层级高的组件的高度
const calcCustomFloatCompHeight = (styleType) => {
  calcAlwaysFloatCompHeight(styleType)
  // customHeightSum = 0
  // 筛选出所有组件悬浮的组件
  const customFloatComponentsList = floatCompList?.filter(item => customFloatComponents.includes(item.styleType))
  customFloatComponentsList.forEach((item, index) => {
    item.index = index
  })
  // 筛选出比当前组件在文档流中层级更高的组件，如果在文档流中是1,2,3排序，在底部悬浮的时候是从上往下3,2,1倒序
  const higherzIndexList = customFloatComponentsList.filter(item => {
    return item.index < customFloatComponentsList.find(item => item.styleType === styleType)?.index
  })
  customFloatComponentsList.forEach(item => {
    if (item.styleType === styleType) {
      item.higherzIndexList = higherzIndexList
    }
  })
  // 当任何一个自定义组件高度有变化时，其他的组件都要重新计算高度
  customFloatComponentsList.forEach(item => {
    if (!item.availableFloat) return
    item.el.style.position = 'fixed'
    item.el.style.zIndex = zIndexEnum[styleType]
    if (!item.higherzIndexList.length) {
      item.el.style.bottom = alwaysFloatBottomComponentsHeight + 'px'
      item.bottomOffset = alwaysFloatBottomComponentsHeight
    } else {
      // 把比自己层级高的组件的高度相加
      item.customHeightSum = item.higherzIndexList.reduce((prev, cur) => {
        if (!cur.availableFloat) return prev
        return prev + cur.compHeight
      }, 0)
      item.el.style.bottom = alwaysFloatBottomComponentsHeight + item.customHeightSum + 'px'
      item.bottomOffset = alwaysFloatBottomComponentsHeight + item.customHeightSum
    }
    // item.el.style.position = 'fixed'
    // 组件悬浮的高度 = 始终悬浮的组件的高度 + 比自己层级高的组件的高度
    // item.el.style.bottom = alwaysFloatBottomComponentsHeight + customHeightSum + 'px'
    // item.el.style.zIndex = zIndexEnum[styleType]
  })
}
// 计算始终悬浮组件的高度
const calcAlwaysFloatCompHeight = () => {
  // 是否包含始终悬浮的组件
  hasAlwaysFloatBottomComponents = floatCompList.filter(item => alwaysFloatBottomComponents.includes(item.styleType)).length

  // 计算始终悬浮的组件的高度
  alwaysFloatBottomComponentsHeight = floatCompList.filter(item => alwaysFloatBottomComponents.includes(item.styleType)).reduce((prev, cur) => {
    cur.availableFloat = true
    return prev + cur.compHeight
  }, 0)
  // 判断是否只有一个不需要留底部10px的组件
  const hasNotNeedBottomSpaceComp = floatCompList.length === 1 && floatCompList.filter(item => notNeedBottomSpace.includes(item.styleType)).length

  // 没有底部tab时，始终悬浮的组件的高度 + 10，为了让组件悬浮后有10px的间距, 只有配置了单图组件的时候留
  if (!alwaysFloatBottomComponentsHeight && !hasNotNeedBottomSpaceComp) alwaysFloatBottomComponentsHeight += 10
}
// 底部悬浮指令
const bottomSticky = {
  inserted: function(el, binding) {
    // let vm = vnode.context
    const { styleType = '', isAllowFloat = false, compId = 0, isFirstScreen = false } = binding?.value || {}
    alwaysFloatBottomComponentsHeight = 0

    if (!isAllowFloat) return

    // 把所有吸顶的组件放到一个数组里面
    initFloatCompList(el, binding)

    // 计算每个组件的高度
    floatCompList.forEach(item => {
      if (!item.compHeight) item.compHeight = calcElHeight(item.el)
    })

    // 计算始终悬浮组件的高度
    calcAlwaysFloatCompHeight(styleType)

    // 如果包含全局悬浮组件，先初始化全局悬浮组件悬浮的高度
    if (globalFloatComponents.includes(styleType)) {
      calcGlobalFloatCompHeightSum(styleType)
    }
    // 监听组件关闭悬浮
    cccxEventBus?.on?.(`close-float-${styleType}-${compId}`, (result) => {
      const { close = false } = result || {}
      if (close) {
        el.style.position = 'static'
        floatCompList.forEach(item => {
          if (item.styleType === styleType) {
            item.closeFloat = true
            item.availableFloat = false
          }
        })
        // 重新计算其他自定义组件的高度
        calcCustomFloatCompHeight(styleType)
        // 重新计算全局悬浮组件的高度
        calcGlobalFloatCompHeightSum(styleType)
      }
    })
    // 包含组件悬浮
    if (customFloatComponents.includes(styleType)) {
      // 使用占位符元素，利用占位符去判断是否在可视区域，如果使用当前悬浮元素判断在可视区，组件悬浮后会一直在可视区域，不会回到文档流中
      const spacer = document.createElement('div')
      spacer.classList.add(`spacer-${styleType}-${compId}`)
      el.parentNode.insertBefore(spacer, el)
      const spacerDom = document.getElementsByClassName(`spacer-${styleType}-${compId}`)?.[0]
      // 防止配置组件在最后一个，占位符没有高度监听不到dom变化
      if (spacerDom) {
        spacerDom.style.height = '1px'
        spacerDom.style.position = 'relative'
        spacerDom.style.top = '-1px'
        spacerDom.style.zIndex = -1
      }
      // 监听元素是否进入/离开可视区域
      const obInstance = new expose({
        observeHide: true,
        exposeRatio: 0.99,
        delay: 100,
      })
      obInstance.observe({
        elements: spacerDom,
        once: false,
      }, ({ exposeDoms }) => {
        observerNum++
        if (!exposeDoms.length) {
          // 首屏的组件第一次挂载完后还在非可视区内不悬浮
          if (observerNum === 1 && isFirstScreen && styleType !== 'ONE_IMAGE_COMPONENT') return
          floatCompList.forEach(item => {
            if (item.styleType === styleType && !item.closeFloat) {
              item.availableFloat = true
            }
          })
          cccxEventBus?.emit?.(`float-${styleType}-${compId}`, {
            type: 'fixed',
          })
          // 如果当前组件已经关闭悬浮，不再悬浮
          if (floatCompList.find(item => item.styleType === styleType)?.closeFloat) return
        } else {
          // 可视区域，把当前组件的availableFloat设置为false
          floatCompList.forEach(item => {
            if (item.styleType === styleType) {
              item.availableFloat = false
            }
          })
          el.style.position = 'static'
          el.style.bottom = 'auto'
          cccxEventBus?.emit?.(`float-${styleType}-${compId}`, {
            type: 'static',
          })
        }
        // 重新计算其他自定义组件的高度
        calcCustomFloatCompHeight(styleType)
        // 组件悬浮影响全局悬浮组件的高度，要重新计算全局悬浮组件的高度
        calcGlobalFloatCompHeightSum(styleType)
      })
    }
  },
  // update: function(el, binding) {
  //   // 处理窗口调整大小时重新定位元素
  //   if (el.style.position === 'fixed') {
  //     window.dispatchEvent(new Event('resize'));
  //   }
  // },
  unbind: function(el, binding) {
    const { styleType = '', compId = 0 } = binding?.value || {}
    // 取消观察元素
    const observer = el.__vueObserver__
    if (observer) {
      observer.disconnect()
      delete el.__vueObserver__
    }
    // 删除占位符元素
    const spacer = el.previousSibling
    if (spacer?.classList?.contains(`spacer-${styleType}-${compId}`)) {
      spacer.parentNode.removeChild(spacer)
    }
    cccxEventBus?.off?.(`float-${styleType}-${compId}`)
    cccxEventBus?.off?.(`close-float-${styleType}-${compId}`)
  },
}

export {
  bottomSticky,
  alwaysFloatBottomComponentsHeight,
}
