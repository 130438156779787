import { asyncLoadFile } from '@shein/common-function'
const { PUBLIC_CDN, SERVER_TYPE } = gbCommonInfo

const configs = {
  central: { appName: 'shein_central_web', partner: 'shein_central', src: `${PUBLIC_CDN}/pwa_dist/libs/risk_libs/fm.us.3.9.1.js` },
  usa: { appName: 'shein_us_web', partner: 'shein_us', src: `${PUBLIC_CDN}/pwa_dist/libs/risk_libs/fm.us.3.9.1.js` },
  eur: { appName: 'shein_eur_web', partner: 'shein_eur', src: `${PUBLIC_CDN}/pwa_dist/libs/risk_libs/fm.eur.3.9.1.js` },
}

const config = configs[SERVER_TYPE] || configs.central
let blackboxPromise = null
export const __blackbox = new Promise((res) => { blackboxPromise = res })
export async function loadTdLibJs () {
  /**
   * 同盾js，风控端要求接入
   */
  window._fmOpt = { 
    partner: config.partner, 
    appName: config.appName, 
    token: config.appName + '-' + new Date().getTime() + '-' + Math.random().toString(16).substr(2),
    is_retry: false,
    collectBehavior: true,
    success: (v) => {
      window._fmOpt.__blackbox = v
      blackboxPromise(v)
    },
    error: () => {
      blackboxPromise('')
    }
  }
  const { ATO_SDK_SWITCH  } = window.SHEIN_LOGIN?.__pre_data || {}
  if (ATO_SDK_SWITCH == 'OFF') return
  if (window._fmOpt.is_retry) return
  if (window._fmOpt.__blackbox) return
  const src = config.src
  await asyncLoadFile({ label: 'script', attrs: { async: true, src } }).then(() => {
    // 记录sdk加载成功
    window._fmOpt.load_success = true
  }).catch(() => {
    loadTdLibJs()
    window._fmOpt.is_retry = true
  })
}
